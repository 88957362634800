import { Controller } from "@hotwired/stimulus"
import L from "leaflet"
import {leafletBlueprint} from "../src/blueprint";

export default class extends Controller {
    static values = {
        lat: Number,
        lng: Number,
        issuestate: String,
        issues: Array
    }

    static targets = [
        'mapbox',
        'map'
    ]

    initialize() {
        this.markers = new Set()
        this.handleMorph = this.handleMorph.bind(this)
    }

    connect() {
        document.addEventListener("turbo:morph", this.handleMorph)
        if (!this.map) {
            this.initializeMap()
        }
    }

    disconnect() {
        document.removeEventListener("turbo:morph", this.handleMorph)
        this.cleanupMap()
    }

    handleMorph(event) {
        const newContainer = event.detail.newElement.querySelector('#blueprint_display_container')
        if (newContainer) {
            const newIssuesValue = newContainer.getAttribute("data-blueprint-display-issues-value")
            if (newIssuesValue) {
                const newIssues = JSON.parse(newIssuesValue)
                if (JSON.stringify(this.issuesValue) !== JSON.stringify(newIssues)) {
                    this.issuesValue = newIssues
                }
            }
        }
    }

    async initializeMap() {
        this.image = this.mapTarget
        this.map = await leafletBlueprint(this.image, this.mapboxTarget, this.mapTarget)
        this.updateMarkers()
    }

    cleanupMap() {
        if (this.markers) {
            this.markers.forEach(marker => {
                if (marker?._map) {
                    marker.remove()
                }
            })
            this.markers.clear()
        }

        if (this.map) {
            this.map.remove()
            this.map = null
        }
    }

    updateMarkers() {
        // Clear existing markers
        this.markers.forEach(marker => {
            if (marker?._map) {
                marker.remove()
            }
        })
        this.markers.clear()

        // Add new markers
        if (this.issuesValue?.length) {
            this.issuesValue.forEach(issue => {
                if (issue.lat && issue.lng) {
                    const marker = this.placeMarker(
                        {
                            lat: issue.lat,
                            lng: issue.lng
                        },
                        issue.issueState
                    )
                    if (marker) {
                        this.markers.add(marker)
                    }
                }
            })
        }
    }

    placeMarker(latlng, issueState) {
        if (!this.map || (latlng.lng === 0 && latlng.lat === 0)) {
            return null;
        }

        const marker = L.marker(latlng, {
            icon: this.markerIcon(issueState),
            draggable: false
        })

        marker.addTo(this.map)
        return marker
    }

    markerIcon(state) {
        return L.divIcon({
            html: this.iconSvg(state),
            className: '',
            iconSize: [32, 32],
            iconAnchor: [16, 32],
        })
    }

    iconSvg(state) {
        if (state === "open") {
            return '<svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10 text-red-800 absolute" viewBox="0 0 20 20" fill="currentColor" id="blueprint_marker">\n' +
                '<path fill-rule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd"/>\n' +
                '</svg>'
        } else if (state === "pending_acceptance") {
            return '<svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10 text-orange-800 absolute" viewBox="0 0 20 20" fill="currentColor" id="blueprint_marker">\n' +
                '<path fill-rule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd"/>\n' +
                '</svg>'
        } else if (state === "done") {
            return '<svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10 text-green-800 absolute" viewBox="0 0 20 20" fill="currentColor" id="blueprint_marker">\n' +
                '<path fill-rule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd"/>\n' +
                '</svg>'
        }
    }

    issuesValueChanged() {
        if (this.map) {
            this.updateMarkers()
        }
    }
}