import L from "leaflet";

// Exporting leafletBlurprint function to reuse in multiple locations
export async function leafletBlueprint(image, mapboxTarget, mapTarget) {
    return new Promise((resolve, reject) => {


        this.image = image;
        this.mapboxTarget = mapboxTarget;
        this.mapTarget = mapTarget;

        this.imageSource = new Image()
        this.imageSource.src = this.image.src;

        const element = document.getElementById("map_fullscreen");

        let that = this;
        this.imageSource.onload = function () {
            let bounds = L.latLngBounds(L.latLng(0, 0), L.latLng(that.image.naturalHeight, that.image.naturalWidth));

            that.map = L.map(that.mapboxTarget, {
                minZoom: -10,
                zoomDelta: 0.5,
                zoomSnap: 0,
                center: [0, 0],
                trackresize: false,
                wheelPxPerZoomLevel: 72,
                inertia: false,
                crs: L.CRS.Simple,
                sleep: true,
                maxBoundsViscosity: 1.0,

                // time(ms) until map sleeps on mouseout
                sleepTime: 750,

                // time(ms) until map wakes on mouseover
                wakeTime: 750,

                // should the user receive wake instructions?
                sleepNote: false,

                // should hovering wake the map? (non-touch devices only)
                hoverToWake: false,

                // a message to inform users about waking the map
                wakeMessage: 'Click or Hover to Wake',

                // a constructor for a control button
                sleepButton: L.Control.sleepMapControl,

                // opacity for the sleeping map
                sleepOpacity: 1,
                dragging: !L.Browser.mobile,

                fullscreenControl: true,
                fullscreenControlOptions: {
                  position: 'topleft',
                  forceSeparateButton: true
                }
            })
            L.imageOverlay(that.mapTarget.src, bounds).addTo(that.map);

            // that.map.setView([0, 0], 1)
            that.map.fitBounds(bounds)
            that.map.setMaxBounds(bounds);

            // self.zimg = L.imageOverlay(self.img.src, self.bounds).addTo(self.map);
            that.map.options.minZoom = that.map.getBoundsZoom(bounds, false);
            that.map.fitBounds(bounds);
            // that.bounds = self.map.getBounds();
            that.map.setMaxBounds(that.map.getBounds());


            // Remove ukraine flag
            that.map.attributionControl.setPrefix('');

            that.map.on('enterFullscreen', function(){
                that.map.fitBounds(bounds);
            });
          
            that.map.on('exitFullscreen', function(){
                that.map.fitBounds(bounds);
            });

            // Finally return the map object so other places can use it
            resolve(that.map)
        }
    })
}

