import { Controller } from "@hotwired/stimulus"
import { DirectUpload } from "@rails/activestorage"

export default class extends Controller {
  static targets = ["buttonText", "icon", "audioList", "attachmentsFields"]

  initialize() {
    this.mediaRecorder = null
    this.audioChunks = []
    this.isRecording = false
    this.isQuickAdd = this.element.dataset.quickAdd === "true" // Determine if this is a quick add button
  }

  connect() {
    if (this.hasButtonTextTarget) {
      // Ensure the button is in its original state when the controller connects
      this.resetButton()
      
      // Listen for Turbo's before-cache event to reset the button before caching the page
      document.addEventListener('turbo:before-cache', this.resetButton.bind(this))
    }
  }

  disconnect() {
    if (this.hasButtonTextTarget) {
      // Cleanup event listener when the controller disconnects
      document.removeEventListener('turbo:before-cache', this.resetButton.bind(this))
      
      // Reset the button when the controller disconnects (e.g., after a redirect)
      this.resetButton()
    }
  }  

  async toggleRecording() {
    if (!this.isRecording) {
      await this.startRecording()
    } else {
      this.stopRecording()
    }
  }

  async startRecording() {
    try {
      console.log("Starting audio recording...")
      this.stream = await navigator.mediaDevices.getUserMedia({ audio: true }) // Store the stream
      this.mediaRecorder = new MediaRecorder(this.stream)
      this.mediaRecorder.ondataavailable = (event) => {
        this.audioChunks.push(event.data)
      }
  
      this.mediaRecorder.onstop = () => {
        this.handleStopRecording()
      }
  
      // Start recording
      this.audioChunks = []
      this.mediaRecorder.start(1000)
      this.isRecording = true
      this.updateUIForRecording(true)
    } catch (error) {
      console.error("Permission denied or error with audio recording:", error)
    }
  }

  stopRecording() {
    this.mediaRecorder.stop()
  
    // Stop all tracks of the media stream to release the microphone
    this.stream.getTracks().forEach(track => track.stop())
  }

  handleStopRecording() {
    const audioBlob = new Blob(this.audioChunks, { type: 'audio/mp4' })
    const audioUrl = URL.createObjectURL(audioBlob)

    // If this is a quick add, update UI for processing and then upload
    if (this.isQuickAdd) {
      this.updateUIForProcessing() // Show processing text
    }

    // if (!this.isQuickAdd) {
    //   // Display audio playback in the list
    //   const audioElement = document.createElement('audio')
    //   audioElement.controls = true
    //   audioElement.src = audioUrl
    //   this.audioListTarget.appendChild(audioElement)    
    // }

    this.uploadAudio(audioBlob)

    // Reset recording state (but don't change UI back yet if it's quick add)
    this.audioChunks = []
    this.isRecording = false
    if (!this.isQuickAdd) {
      this.updateUIForRecording(false)
    }
  }

  uploadAudio(blob) {
    const file = new File([blob], `audio-recording-${Date.now()}.mp4`, {
      type: 'audio/mp4',
      lastModified: Date.now()
    })

    const upload = new DirectUpload(file, "/rails/active_storage/direct_uploads")

    upload.create((error, blob) => {
      if (error) {
        console.error("Direct upload error:", error)
      } else {
        if (this.isQuickAdd) {
          this.redirectToNewIssueForm(blob.signed_id)
        } else {
          this.appendBlobToForm(blob.signed_id)
        }
      }
    })
  }

  updateUIForProcessing() {
    // Change button text to "Processing..."
    this.buttonTextTarget.textContent = ""
    // Hide the recording icon during processing
    this.iconTarget.style.display = "none"
  }

  redirectToNewIssueForm(signedId) {
    // Get the new_issue_url from the data attribute
    const newIssueUrl = this.element.dataset.audioRecorderNewIssueUrlValue

    // Construct the full URL with the signed ID
    const urlWithBlob = `${newIssueUrl}?blob=${signedId}`
    Turbo.visit(urlWithBlob)
  }

  appendBlobToForm(signedId) {
    // Append hidden input with the blob's signed ID to the form's attachments field
    const hiddenField = document.createElement("input")
    hiddenField.setAttribute("type", "hidden")
    hiddenField.setAttribute("name", "issue[attachments][]")
    hiddenField.setAttribute("value", signedId)
    this.attachmentsFieldsTarget.appendChild(hiddenField)

    // Reset UI back after processing and uploading
    this.updateUIForRecording(false)

    document.getElementById("assistant_submit_btn").click()

  }

  updateUIForRecording(isRecording) {
    if (isRecording) {
      this.buttonTextTarget.textContent = ""
      this.iconTarget.style.display = "inline"
    } else {
      this.buttonTextTarget.textContent = ""
      this.iconTarget.style.display = "none"
    }
  }

  resetButton() {
    // Reset the button text and icon to its original state
    this.buttonTextTarget.textContent = ""
    this.iconTarget.style.display = "none"
  }
}
